html,body{
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  /* margin: 0; */
  font-family:  'Roboto', -apple-system, BlinkMacSystemFont,'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

.btn,.form-control>input {
  -webkit-border-radius: 0 !important;
     -moz-border-radius: 0 !important;
          border-radius: 0 !important;
}
.center-text{
  text-align: center;
}
.image-crop{
  position: relative;
}
.container-fluid{
  margin: 0;
  padding: 0;
}
.nopadding{
  padding:0;
}
.padding-header{
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.nopadding-header{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.scrolller::-webkit-scrollbar {
  display: none;
}
.scrolller {
  -ms-overflow-style: none;
}
.strike-line{
  text-decoration: line-through;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container{
  margin:0;
  padding:0;
}
.border-test{
  border:1px solid grey
}

.dashboard,.App{
  font-family: 'Roboto';
}
.resturant-header{
  font-family: 'Roboto';
  z-index: 10001;
  text-align: center;
  position: fixed;
  width: 100%;
  top:0;
  left: 0;
  right: 0;
  -webkit-box-shadow: 0px 1px 5px 1px rgba(133,131,133,0.5);
-moz-box-shadow: 0px 1px 5px 1px rgba(133,131,133,0.5);
box-shadow: 0px 1px 5px 1px rgba(133,131,133,0.5);
}
.resturant-detail{
  text-align: left;
}
.background-orange{
  background-color: #f7a600;
  color:white;
  height:51px
}
.background-grey{
  background-color: #cbc1a9;
  color:black;
  height:51px
}
.resturant-details-info>a{
  color:#1a171c
}
.resturant-details-info>a:hover{
  color:#1a171c
}
.arrow-menu{
  font-size:50px;
  margin-top: -25px;
  color:#6e6a5e
}
.background-grey-card{
  padding:15px;
  margin-bottom: 15px;
  font-family: 'Roboto';
  font-weight: bold;
  text-decoration: none;
}

.background-grey-card-odd{
  background-color: #cac9ca;
  color:black;

}
.background-grey-card-pair{
  background-color: #464546;
  color:white;

}
.menu-contain{
  padding:15px 0;
  text-align:left;
  font-size: 18px;
  width: 100%;
}
.background-menu-item-head{
  background-color: #6e6a5e;
  color:white;
  padding:5px;
  padding-left: 18px;
  text-align: left;
}
.menu-item{
  color:white;
  border-bottom:1px solid lightgray;
  padding:5px;
  padding-left: 35px;
  outline: none;
}
.menu-item>a{
  text-decoration: none;
}
.menu-item:hover{
  color:black;
  background-color:lightgrey;
}
.bm-menu,.bm-item-list{
  padding:0 !important;
  padding-top: 40px !important;
}
.link-app{
  color:#000000;
  font-size: 25px;
  font-weight: lighter;
}
.link-app:active,.link-app:hover{
  color:#000000;
}
.div-qrcode,.div-qrcode-open{
  max-width: 600px;
  margin: 0 auto;
  height: 0;
  max-height: 600px;
  background-color: #8b8a8e;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.div-qrcode-open {
  padding-bottom:100%;
  max-height: 600px;
}

.menu-flow{
  font-weight: bold;
  background-color: #ffffff;
  color:#6e6a5e;
  padding:5px;
  font-size: 16px;
  text-align: left;
}
.menu-plate{
  font-weight: 100;
  font-size: 15px;
}
.menu-body{
  font-family: 'Roboto';
}
.resturant-name-div{
  text-align: left;
  height: 50px;
  line-height: 40px;
}
.resturant-name{
  font-family: 'Roboto';
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  padding-left: 0;
}
.fa-heart{
  opacity: 0.4;
}
.fa-heart-active{
  transition: all 2s;
  opacity: 1;
}
.resturant-details-name{
  font-family: 'Roboto';
  font-size: 25px;
  color:#1a171c;
  padding-bottom:15px;
}
.resturant-details-subtitle{
  font-family: 'Roboto';
  font-size: 18px;
  color:#1a171c;
  padding-bottom:15px;
}
.resturant-details-info{
  font-family: 'Roboto';
  font-size: 18px;
  color:#1a171c
}

.plate{
  padding:5px
}

.ingredients, .qrReader, .search{
  height: 0;
  opacity: 0;
}
.search-active{
  margin-top:25px !important;
  margin:0 auto;
}
.ingredients-active, .qrReader-active,.search-active{
  transition: height 0.5s, opacity 0.5s;
  height: auto;
  opacity: 1;
}
.fa-angle-down{
  transition: all 0.5s;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
}
.select-coolname{
  padding: .25em;
  border: 0;
  color:#1a171c;
  background-color: white;
  border-bottom: 2px solid #1a171c;
  font-weight: 700;
  letter-spacing: .15em;
  font-size: 13px;
  border-radius: 0;
  width: 100%;
  outline: 0;
}
.fa-angle-down-rotate{
  transition: all 0.5s;
  transform: rotate(90deg);
   -webkit-transform: rotate(90deg);
   -ms-transform: rotate(90deg);
}
.notes{
  margin-top: 10px;
  font-style: italic;
  font-size: 14px;
  text-align: left;
}
.img-search{
  max-width:100%;
  max-Height:60px;
  vertical-align: baseline;
  position: absolute;
  padding-top: 5px;
  padding-left: 13px;
  top:3px;
  left:13px;
}
.hidden { display: none; }
.lbl {
  position: relative;
  display: block;
  height: 20px;
  width: 44px;
  background: #898989;
  -webkit-border-radius: 100px !important;
  -moz-border-radius: 100px !important;
  border-radius: 100px !important;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fixed-width-search {
  display: inline-block;
  width: 70px;
}
.variable-width-search {
  display: inline-block;
  width: calc(100% - 70px);
  padding-left: 5px;
  height: 51px;
}

.fixed-width-header {
  display: inline-block;
  margin-top: -3px;
  width: 100px;
}
.fixed-width-header2 {
  display: inline-block;
  width: 30px;
  padding-top: 5px;;
}
.variable-width-header {
  display: inline-block;
  margin-top: -3px !important;
  width: calc(100% - 130px);
  height: 51px;
}
.background-image-fullscreen{
  z-index: -2;
  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#root{
  height: 100%;
}
.homepage-bar-bottom{
  margin-top: -160px;
}
.homepage-info-back{
  background-color: #4a4e57;
  padding:15px;
  margin-top: 10px;
}
.homepage-info-dettail, .homepage-info-dettail-center{
  height: 100%;
  min-height: 120px;
  color:whitesmoke;
}
.text-homepage{
  text-align:right;
  margin:20px 0;
  /* text-shadow: 1px 1px 1px rgb(133, 133, 133) */
}
.image-logo{
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
  width:45px; 
  height:45px;
  margin:0 auto;
  padding:0;
}
.homepage-info-dettail-center{
  border-left: 1px solid whitesmoke;
}
/* .ReactCrop__image{
  max-width: 300px !important;
  margin:0 auto;
} */
.ReactCrop__image {
  display: block;
  max-width: 100%;
  max-height: 400px;
}
.lbl:after {
  position: absolute;
  left: -2px;
  top: -3px;
  display: block;
  width: 26px;
  height: 26px;
  -webkit-border-radius: 100px !important;
  -moz-border-radius: 100px !important;
  border-radius: 100px !important;
  background: rgb(171, 171, 171);
  box-shadow: 0px 3px 3px rgba(0,0,0,0.05);
  content: '';
  transition: all 0.3s ease;
}

.lbl:active:after { transform: scale(1.15, 0.85); }

.cbx:checked ~ label { background: #ffce96; }

.cbx:checked ~ label:after {
  left: 20px;
  background: #f7a600;
}
.btn-outline-warning{
  color:black !important
}
.sr-only{
  font-size: 25px;
  margin: 0 auto;
  text-align: center;
}
.spinner-bootstrap{
  margin-top: 200px;
}
.cbx:disabled ~ label {
  background: #ffce96;
  pointer-events: none;
}
.item-list-odd,.item-list{
  font-family: "Roboto";
  font-size: 14px;
  padding:10px 5px;
  margin-top: 3px;
  text-align: left;
}
.homepage-bar-bottom{
  font-family: "Roboto";
}
.item-list-odd{
  background-color: #e2e1e1;
  color:black;
}
.item-list{
  background-color: #efefef;
  color:black;
}
.cbx:disabled ~ label:after { background: #bcbdbc; }

.fab {
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed !important;
  border: none;
  background: none;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
       border-radius: 50px !important;
  outline:none !important;
}

.input-seaarch{
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #1a171c;
  width: 100%;
  color:#1a171c;
  opacity: 0.7;
}
/* ::placeholder { 
  color: #3d3d3d;
  font-family: 'Roboto';
  font-size: 14px;
  opacity: 0.7;
}

:-ms-input-placeholder { 
  color: #3d3d3d;;
  font-size: 14px;
  font-family: 'Roboto'
}

::-ms-input-placeholder {
  color: #3d3d3d;;
  font-size: 14px;
  font-family: 'Roboto'
} */
.has-search .form-control {
  padding-left: 2.375rem;
}
.search-odd, .search-pair{
  min-height: 50px;
  margin-top:3px;
  margin-bottom:3px;
}
.search-odd{
  background-color: rgb(224, 221, 221);
}
.search-pair{
  background-color: rgb(195, 195, 195);
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.hide_show{
  background-color:#f4f4f2;
  border-bottom: 1px solid rgb(210, 209, 209);
  text-align:left;
  padding:5px;
  margin:"0 10px" 
}
.flow-odd,.flow-pair{
  border-bottom:1px solid black;
  padding:10px;
}
.flow-odd{
  background-color: #f5f5f5;
}
.flow-pair{
  background-color: #e7e7e7;
}
.form-group > label{
  margin-bottom: -5px;
  font-weight: bold;
  font-size: 14px;
}
.right-checkbox{
  margin-left: 10px;
}

.main-price,.no-main-price{
  font-family: 'Roboto';
  font-size: 30px;
  font-weight: bold;
}
.main-price-mobile{

}
.no-main-price{
  font-size: 18px;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  /* border-color: rgba(126, 239, 104, 0.8); */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 2px rgba(159, 159, 159, 0.6);
  outline: 0 none;
}

.wpcc-container{
  font-size: 13px;
  padding:5px;
}
